import React from 'react'

const CallToA = () => {
  return (
    <section
    id="clients"
    className="max-container max-sm:mt-12"
  >
    <div
      className="flex flex-col justify-start gap-5"
    >
       {/**<!-- CTA Section --> */}
  <section id="cta" className="bg-white font-raleway">
    {/**Flex container */}
    <div
      class="container flex flex-col items-center justify-between px-6 py-24 mx-auto space-y-12 md:py-12 md:flex-row md:space-y-0"
    >
     {/**<!-- Heading --> */}
      <h2
        class="text-5xl font-bold text-center text-black md:text-4xl md:max-w-xl md:text-left"
      >
        Simplify how your team works today
      </h2>
      {/**<!--Buttin --> */}
      <div>
        <a
          href="#"
          class="p-6 px-20 text-white bg-gold rounded-full shadow-2xl baseline hover:bg-gray-900"
          >Get Started</a
        >
      </div>
    </div>
  </section>

    </div>
  </section>
  )
}

export default CallToA