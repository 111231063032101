import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Pagination } from "@mantine/core";
import { setSearchTerm } from "../../store/slices/searchTerm";
import { FaSearch, FaTrash } from "react-icons/fa";
import { labelBackground } from "../../utils/labelColors";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { closeModal } from "../../store/slices/authSlice";
import { updateJobListing } from "../../store/slices/jobListingSlice";
import ApproveApplication from "../model_content/ApproveApplication";
import { createNotification } from "../../store/slices/notifications";
import { exportCsvApi } from "../../api/exportCsvApi";
import { toast } from "react-toastify";

const AllJobListings = () => {
  const [disApprovalReason, setDisApprovalReason] = useState("");
  const [opened, { open, close }] = useDisclosure(false); // for the modal
  const [selectedListing, setSelectedListing] = useState(null);

  const [loading, setLoading] = useState(null);
  const searchTerm = useSelector((state) => state.searchTerm);
  const { listings } = useSelector((state) => state.jobListing);
  const dispatch = useDispatch();

  // pagination property
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // csv file exclude columns
  const exclude_columns = [
    "id",
    "user_id",
    "created_at",
    "updated_at",
    "job_listing_id",
    "job_seeker_id",
    "user_id",
    "has_run_tour",
    "is_profile_complete",
    "country_code",
    "__class__",
  ];

  // handle csv export
  const handleExportCsv = async () => {
    try {
      exportCsvApi({
        outputFileName: `Job-Postings-${
          new Date().toISOString().split("T")[0]
        }.csv`,
        resource: "listings",
        values: {
          exclude_columns: exclude_columns,
        },
      });
    } catch (error) {
      // toast.error(error);
      throw window.alert(error);
    }
  };

  // filter by subscription plan
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // handle modal open
  const handleModalOpen = (listing) => {
    setSelectedListing(listing);
    open();
  };

  //close Modal
  const handleClose = () => {
    dispatch(closeModal());
    close();
  };

  // approve job listing
  const handleApproveJobListing = async () => {
    setLoading("approve");
    try {
      console.log(loading);
      await dispatch(
        updateJobListing({
          value: { status: "approved" },
          id: selectedListing.id,
        })
      );

      // dispatch notification to the user/employer
      const values = {
        company_id: selectedListing.company_id,
        sender_id: "Admin",
        meta_data: selectedListing.id, //the listing id
        type: "job_listing_approved",
        message: `Congratulations!!! we are happy to inform you that your job listing for <span className="font-semibold text-gray-800"> ${selectedListing.job_title}</span> has been approved.`,
      };
      // send a notification to the user
      dispatch(createNotification(values));
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading("finally");
    }
  };

  // reject job listing
  const handleRejectJobListing = async () => {
    try {
      setLoading("reject");
      await dispatch(
        updateJobListing({
          value: { status: "disapproved" },
          id: selectedListing.id,
        })
      );

      // dispatch notification to the user/employer
      const values = {
        company_id: selectedListing.company_id,
        sender_id: "Admin",
        meta_data: selectedListing.id, //the listing id
        type: "job_listing_disapproved",
        message: `We are sorry to inform you that your job listing for <span className="font-bold text-gray-800"> ${selectedListing.job_title}</span> has been disapproved. Reason: <span className="text-sm font-semibold text-gray-800">${disApprovalReason}<span>`,
      };
      // send a notification to the user
      dispatch(createNotification(values));
      setDisApprovalReason("");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(null);
    }
  };

  // search filter
  const filteredJobListing = listings.filter((listing) => {
    // Check if the listing name matches the search term
    const matchesSearchTerm =
      // listing.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.job_title.toLowerCase().includes(searchTerm.toLowerCase());

    // Check if the listing matches the selected filter
    let matchesFilter;
    switch (filter) {
      case "all":
        matchesFilter = true;
        break;
      case "pending":
        matchesFilter = listing.status === "pending";
        break;
      case "approved":
        matchesFilter = listing.status === "approved";
        break;
      case "disapproved":
        matchesFilter = listing.status === "disapproved";
        break;
      default:
        matchesFilter = true;
    }

    // Return true if the listing matches both the search term and the filter
    return matchesSearchTerm && matchesFilter;
  });

  // table rows
  const rows = filteredJobListing
    ? filteredJobListing
        .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
        .map((listing) => {
          let label_bg = labelBackground(listing.status);
          return (
            <Table.Tr
              key={listing?.id}
              className=" border-b  rounded-md"
              onClick={() => handleModalOpen(listing)}
            >
              <Table.Td className="p-4">{listing?.company_name}</Table.Td>
              <Table.Td>{listing?.job_title}</Table.Td>
              <Table.Td>{listing?.application_count}</Table.Td>
              <Table.Td>{`${
                listing?.is_expired ? "Expired" : "Open"
              }`}</Table.Td>
              <Table.Td
                className={`${label_bg} mt-2 inline-block`}
              >{`${listing?.status}`}</Table.Td>
              <Table.Td>
                <FaTrash className="text-xl" title="Delete Listingd" />
              </Table.Td>
            </Table.Tr>
          );
        })
    : [];

  useEffect(() => {
    dispatch(setSearchTerm(""));
  }, []);
  return (
    <>
      <div>
        <h1 className="px-8 text-2xl font-bold mt-4">All Job listings</h1>
        <div className="p-8">
          <section className="border bg-white rounded p-8">
            <div className="flex items-center justify-between mb-4">
              <div className="pl-2 border w-[420px] bg-gray-100 flex gap-3 items-center border-gray-500 rounded">
                <FaSearch />
                <input
                  onChange={(e) => dispatch(setSearchTerm(e.target.value))}
                  type="text"
                  className="flex-1 h-full p-2 focus:outline-none rounded bg-transparent"
                  placeholder="search company name, job title..."
                />
              </div>
              <div className="w-[400px] flex items-center gap-5">
                <div>
                  Total: <span>{filteredJobListing?.length}</span>
                </div>
                <select
                  value={filter}
                  onChange={handleFilterChange}
                  name="status"
                  className="flex-1 p-2 border border-gray-600"
                >
                  <option value="">All</option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="disapproved">Disapproved</option>
                </select>
                {listings?.length > 0 && (
                  <button
                    onClick={handleExportCsv}
                    className="text-white p-2 px-4 font-semibold font-raleway bg-black
              rounded-md cursor-pointer"
                  >
                    Export CSV
                  </button>
                )}
              </div>
            </div>

            {/* Table */}
            <div>
              <Table
                // verticalSpacing={"md"}
                className="w-full  bg-white rounded-lg border-red-600"
                striped
                highlightOnHover
              >
                <Table.Thead className="rounded-lg text-gray-800 bg-gold">
                  <Table.Tr className="text-left rounded-lg bg-gold">
                    <Table.Th className=" font-semibold p-4 rounded-tl-lg">
                      Company Name
                    </Table.Th>
                    <Table.Th className=" font-semibold">Job Title</Table.Th>
                    <Table.Th className="text-sm font-semibold">
                      No of Applicant
                    </Table.Th>
                    <Table.Th className="text-sm font-semibold">
                      Validity
                    </Table.Th>
                    <Table.Th className="text-sm font-semibold">
                      Status
                    </Table.Th>
                    <Table.Th className="text-sm font-semibold rounded-tr-lg">
                      Action
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
              </Table>
            </div>
          </section>
          {/* Pagination */}
          <div className="flex justify-center mt-6">
            <Pagination
              total={Math.ceil(filteredJobListing?.length / rowsPerPage) || 1}
              value={currentPage}
              onChange={setCurrentPage}
              color="#333"
            />
          </div>
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={handleClose}
        title="Approve Application"
        size={"80%"}
      >
        <ApproveApplication
          approveJobListing={handleApproveJobListing}
          rejectListing={handleRejectJobListing}
          setDisApprovalReason={setDisApprovalReason}
          disApprovalReason={disApprovalReason}
          {...selectedListing}
          loading={loading}
        />
      </Modal>
    </>
  );
};

export default AllJobListings;
