import { reviews } from "../constants"
import ReviewCard from "../components/ReviewCard"

const Reviews = () => {
  return (
    <section className="max-container">
      <h3 className="font-raleway text-center text-4xl font-bold">
        Client 
        <span className="text-[#ceac46]"> success</span> stories
      </h3>
      <p className="info-text m-auto mt-4 max-w-lg text-center">Explore how GSCmatchpoint has helped businesses like yours succeed. Read testimonials and success stories from our satisfied clients.</p>

      <div className="mt-24 flex flex-1 justify-evenly items-center max-lg:flex-col gap-14">
        {reviews.map((review) =>(
          <ReviewCard 
            key={review.customerName}
            imgURL={review.imgURL}
            customerName={review.customerName}
            rating={review.rating}
            feedback={review.feedback}
          />
        ))}
      </div>

    </section>
  )
}

export default Reviews