import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { ProfileNavbarBottomLinks, ProfileNavbarLinks } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/authSlice";
import getUserType from "../../utils/getUser";

const linkClasses =
  "flex items-center gap-4 font-raleway px-3 py-2 my-2 hover:bg-gold hover:text-white hover:no-underline rounded-sm text-base";

const Sidebar = () => {
  const userType = getUserType();
  let daysSinceCreation;

  const created_at = useSelector(
    (state) => state?.company.company?.company?.created_at
  );

  const calculateDaysSinceCreation = (createdAt) => {
    const currentDate = new Date();
    const creationDate = new Date(createdAt);
    const differenceInTime = currentDate.getTime() - creationDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays;
  };

  if (created_at) {
    daysSinceCreation = calculateDaysSinceCreation(created_at);
  }

  return (
    <div className="flex max-sm:w-20 max-sm:rounded-none max-sm:mx-0 h-screen flex-col bg-neutral-200 w-1/6 px-5 py-10 ml-3 mb-3 rounded-md">
      <div className="flex-1 max-sm:w-full">
        {ProfileNavbarLinks.map((item) => {
          if (item.key === "membership" && userType !== "company") {
            return null; // Do not render anything for non-company users when the item is "membership"
          }
          return <SidebarLink key={item.key} item={item} />;
        })}
      </div>

      <div>
        {userType === "company" && daysSinceCreation <= 7 && (
          <div className="text-sm bg-gold w-full p-2 rounded shadow shadow-md font-bold">
            <span className="font-light">Free Trial: </span>
            {7 - Math.floor(daysSinceCreation)} days left
          </div>
        )}
        <div className="flex flex-col pt-2 border-t border-neutral-400">
          {ProfileNavbarBottomLinks.map((item) => (
            <SidebarLink key={item.key} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

// Another component that is used in the Sidebar component
function SidebarLink({ item }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { notifications } = useSelector((state) => state?.notification);

  // Unread notifications
  const unReadNotifications = notifications?.filter(
    (notification) => !notification.is_read
  );

  //  Handle logging out
  const handleClick = () => {
    if (item.key === "logout") {
      // Dispatch LOGOUT action
      dispatch(logout());
      // And then redirect them to the login page:
      navigate("/");
    }
  };

  const details = item.key === "details" && "__details_joyride";
  const documents = item.key === "documents" && "__documents_joyride";
  const membership = item.key === "membership" && "__joyride_membership_btn";
  const settings = item.key === "settings" && "__joyride_settings";

  return (
    <Link
      to={item.path}
      onClick={handleClick}
      className={classNames(
        pathname === item.path ? "bg-white" : "",
        `${linkClasses} ${details} ${documents} ${membership} ${settings}`
      )}
    >
      <div className="flex items-center justify-between w-full max-sm:p-0">
        <p className="flex items-centerflex-1">
          <span className="text-xl mr-2 relative">
            {item.icon}

            {item.key === "notifications" && (
              <span
                className={`border-2 border-neutral-200 absolute top-0 right-0 h-[10px] w-[10px] rounded-full ${
                  unReadNotifications.length > 0 ? "bg-red-500" : "bg-gray-400"
                }`}
              ></span>
            )}
          </span>
          <span className="max-sm:hidden max-sm:hover:show">{item.label}</span>
        </p>
        {item.key === "notifications" && (
          <span className="flex items-center justify-center w-[20px] h-[20px] rounded bg-gray-800 text-gray-200 max-sm:hidden">
            {unReadNotifications.length}
          </span>
        )}
      </div>
    </Link>
  );
}

export default Sidebar;
