import axios from "axios";
import { BASE_URL } from "../constants";

// Handle User login
export const loginUserApi = async (cred) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, cred);
    return response;
  } catch (error) {
    throw error;
  }
};

// Handle Google login
export const googleLoginApi = async (access_token) => {
  try {
    const response = await axios.get(`${BASE_URL}/auth/google/login`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Handle User signup
export const signupUserApi = async (values) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/signup`, values);
    return response;
  } catch (error) {
    throw error;
  }
};

//Google Signup
export const googleSignupApi = async ({ value, access_token }) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/google/signup`, value, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Verify Email
export const verifyEmailApi = async (otp) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/verify_email`, otp);
    return response;
  } catch (error) {
    throw error;
  }
};

// Request new token
export const requestNewTokenApi = async (email) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/request_token`, email);
    return response;
  } catch (error) {
    throw error;
  }
};

// Access refresh token and get new access token
export const refreshAccessTokenApi = async (refresh_token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/auth/token-refresh`,
      refresh_token
    );
    return response;
  } catch (error) {
    throw error;
  }
};

//  Update Passsword
export const updatePasswordApi = async (values) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/auth/update_password`,
      values
    );
    return response;
  } catch (error) {
    throw error;
  }
};
