import React, {  useState } from "react";
import Button from "../components/Button";
import { arrowRight, search } from "../assets/icons";
import { MatchManHero } from "../assets/images";
import { useDispatch } from "react-redux";
import { setSearchTerm } from "../store/slices/searchTerm";
import { Link } from "react-router-dom";

const Hero = () => {
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();
  // Handle searches
  const handleSearchChange = () => {
    dispatch(setSearchTerm(searchInput));
    window.location.href = "#listings";
  };


  return (
    <section
      id="home"
      className="w-full flex xl:flex-row flex-col justify-center min-h-screen gap-10 max-container"
    >
      <div className="relative xl:w-3/5 flex flex-col justify-center items-start w-full max-sm:w-full  max-xl:padding-x pt-28 pl-20">
        <h1 className="mt-10 font-raleway text-8xl max-sm:text-[50px] max-sm:w-full max-sm:text-center max-sm:leading-[40px] font-bold">
          <span>The nexus of </span>
          <br />
          <span className="text-[#ceac46] inline-block mt-3">opportunities </span>
          <span> and Potential </span>
        </h1>
        <p className="font-raleway text-slate-gray text-lg max-sm:text-center leading-8 mt-6 mb-5 max-sm:mb-2 sm:max-w-sm">
        Discover the Best Opportunities for Your Career and Business Success
        </p>

        <div className="lg:max-w-[70%] w-full flex items-center max-sm:flex-col mb-5 max-sm:mb-0 gap-5 p-2.5 sm:border sm:border-slate-gray rounded-lg max-sm:rounded-lg ">
          <input
            type="text"
            placeholder="Search e.g. Designer"
            className="input text-gray-800"
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <div className="flex max-sm:justify-end items-center max-sm:full max-sm:w-full p-2">
            <img
              onClick={handleSearchChange}
              src={search}
              alt=""
              height={24}
              width={24}
              className="cursor-pointer flex max-sm:inline-block max-sm:absolute max-sm:mb-28 max-sm:mr-3 "
            />
          </div>
        </div>

        <Link className="max-sm:w-full" to={"/listings"}>
          <div className="px-0 flex max-sm:justify-center items-center max-sm:full max:sm:absolute max-sm:mb-8 max-sm:w-full p-2">
            <Button label="Explore Now" iconURL={arrowRight} />
          </div>
        </Link>
      </div>

      <div className="max-sm:hidden relative flex-1 flex justify-center items-center xl:min-h-screen max-sm: max-xl:py-40 bg-primary bg-heroBack bg-cover bg-center">
        <img
          className="py-10 object-contain relative z-10"
          src={MatchManHero}
          alt="HeroImage"
          width={800}
          height={700}
        />
      </div>
    </section>
  );
};

export default Hero;
