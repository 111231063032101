import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  fetchJobApplicationApi,
  fetchSingleJobApplicationApi,
  createJobApplicationApi,
  updateJobApplicationApi,
  deleteJobApplicationApi,
} from "../../api/jobApplicationApi";

const initialState = {
  applications: [],
  loading: false,
  error: null,
  singleApplication: {},
};

// fetch all applications
export const fetchJobApplications = createAsyncThunk(
  "jobApplication/fetchJobApplications",
  async ({ ...params }, thunkApi) => {
    try {
      const response = await fetchJobApplicationApi({ ...params });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// fetch single application
export const fetchSingleJobApplication = createAsyncThunk(
  "jobApplication/fetchSingleJobApplication",
  async (id, thunkApi) => {
    try {
      const response = await fetchSingleJobApplicationApi(id);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// Create a new job application
export const createJobApplication = createAsyncThunk(
  "jobApplication/createJobApplication",
  async ({ values, callBack }, { rejectWithValue }) => {
    const { resetForm, navigateToApplications } = callBack;
    try {
      const response = await createJobApplicationApi(values);
      if (response.status >= 200 && response.status < 300) {
        toast.success("New Application Successfully Created");
        resetForm();
        navigateToApplications();
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.detail);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Update a job application
export const updateJobApplication = createAsyncThunk(
  "jobApplication/updateJobApplication",
  async ({ id, values }, { rejectWithValue }) => {
    try {
      const response = await updateJobApplicationApi(id, values);
      if (response.status === 200) {
        toast.success("Application Approved");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete a job application
export const deleteJobApplication = createAsyncThunk(
  "jobApplication/deleteJobApplication",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteJobApplicationApi(id);
      if (response.status === 200) {
        toast.success("Successfully Deleted");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const jobApplicationSlice = createSlice({
  name: "jobApplication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobApplications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobApplications.fulfilled, (state, action) => {
        state.applications = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchJobApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleJobApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleJobApplication.fulfilled, (state, action) => {
        state.singleApplication = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSingleJobApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createJobApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createJobApplication.fulfilled, (state, action) => {
        state.applications.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createJobApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateJobApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateJobApplication.fulfilled, (state, action) => {
        state.applications = state.applications.map((application) =>
          application.id === action.payload.id ? action.payload : application
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(updateJobApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteJobApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteJobApplication.fulfilled, (state, action) => {
        state.applications = state.applications.filter(
          (application) => application.id !== action.payload
        );

        state.loading = false;
        state.error = null;
      })
      .addCase(deleteJobApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default jobApplicationSlice.reducer;
