import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import getUserType from "../utils/getUser";

import AdminLayout from "../admin/layouts/AdminLayout";
import AdminRoutes from "../utils/AdminRoute";
import Homepage from "../pages/Homepage";
import About from "../pages/AboutUs";
import Membership from "../pages/OurMembership";
import Contact from "../pages/ContactUs";
import UserLogin from "../pages/UserLogin";
import PostJob from "../pages/PostJob";
import Apply from "../pages/Apply";
import SignUp from "../pages/SignUp";
import ApplicationPage from "../pages/ApplicationPage";
import Dashboard from "../components/Dashboard";
import UserProfile from "../pages/UserProfile";
import ProfileDetails from "../components/ProfileDetails";
import Notifications from "../components/Notifications";
import Documents from "../components/Documents";
import Settings from "../components/Settings";
import Support from "../components/Support";
import Applications from "../components/Applications";
import EmailVerification from "../pages/EmailVerification";
import PublicLayout from "../layouts/PublicLayout";
import AdminDashBoard from "../admin/components/AdminDashBoard";
import PrivateRoutes from "../utils/PrivavteRoutes";
import CompanyApplicantLists from "../components/CompanyApplicantLists";
import CompanySummaryPage from "../components/CompanySummaryPage";
import JobSeekerSummary from "../components/JobSeekerSummary";
import JobSeekerPreferenceSettings from "../components/JobSeekerPreferenceSettings";
import JobSeekerAccountSettings from "../components/JobSeekerAccountSettings";
import JobSeekerNotificationSettings from "../components/JobSeekerNotificationSettings";
import CompanyAccountSettings from "../components/CompanyAccountSettings";
import ManageSubscriptionSettings from "../components/ManageSubscriptionSettings";
import TermsOfUse from "../pages/TermsOfUse";
import PrivacyPolicy from "../pages/PrivacyPolicy";

//  Admin
import UserLayout from "../admin/layouts/UserLayout";
import AllEmployers from "../admin/company/AllEmployers";
import AllJobSeekers from "../admin/jobSeeker/AllJobSeekers";
import AllJobListings from "../admin/company/AllJobListings";
import SettingsLayout from "../admin/layouts/SettingsLayout";
import CreateAdminUser from "../admin/pages/CreateAdminUser";
import UpdatePassword from "../admin/pages/UpdatePassword";
import ManageAdminUsers from "../admin/pages/ManageAdminUsers";
import Notification from "../admin/pages/Notification";
import Subscribers from "../admin/pages/Subscribers";
import PostJobs from "../admin/pages/PostJobs";

const userType = getUserType();

export const Routes = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<PublicLayout />}>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/signin" element={<UserLogin />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/postjob" element={<PostJob />} />
        <Route path="/listings" element={<Apply />} />
        <Route path="/apply/:id" element={<ApplicationPage />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/verification" element={<EmailVerification />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/user" element={<UserProfile />}>
            <Route index={true} element={<Dashboard />} />
            <Route path="details" element={<ProfileDetails />} />
            <Route path="membership" element={<Membership />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="applications" element={<Applications />} />
            <Route
              path="applications/:id"
              element={<CompanyApplicantLists />}
            />
            <Route
              path="applications/applicant-profile/:id"
              element={<JobSeekerSummary />}
            />
            <Route
              path="applications/company-profile/:id"
              element={<CompanySummaryPage />}
            />

            <Route path="documents" element={<Documents />} />
            <Route path="support" element={<Support />} />
            <Route path="settings" element={<Settings />}>
              <Route
                path=""
                element={
                  userType === "job_seeker" ? (
                    <Navigate to={"preferences"} />
                  ) : (
                    <Navigate to={"manage-subscription"} />
                  )
                }
              />

              {userType === "job_seeker" ? (
                <Route
                  index
                  path="preferences"
                  element={<JobSeekerPreferenceSettings />}
                />
              ) : (
                <Route
                  index
                  path="manage-subscription"
                  element={<ManageSubscriptionSettings />}
                />
              )}
              <Route path="account" element={<JobSeekerAccountSettings />} />

              <Route
                path="notifications"
                element={<JobSeekerNotificationSettings />}
              />

              {/* {userType === "company" && (
                <Route
                  index
                  path="manage-subscription"
                  element={<ManageSubscriptionSettings />}
                />
              )} */}

              <Route
                index
                path="company-account"
                element={<CompanyAccountSettings />}
              />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route element={<AdminRoutes />}>
        <Route path="/admin" element={<AdminLayout />}>
          <Route index={true} element={<AdminDashBoard />} />
          <Route path="users" element={<UserLayout />}>
            <Route path="" element={<Navigate to={"employers"} />} />
            <Route path="employers" element={<AllEmployers />} />
            <Route path="applicants" element={<AllJobSeekers />} />
          </Route>
          <Route path="applications" element={<AllJobListings />} />
          <Route path="notifications" element={<Notification />} />
          <Route path="settings" element={<SettingsLayout />}>
            <Route path="" element={<Navigate to={"create-admin-user"} />} />
            <Route path="create-admin-user" element={<CreateAdminUser />} />
            <Route path="update-password" element={<UpdatePassword />} />
            <Route path="manage-admin-users" element={<ManageAdminUsers />} />
          </Route>
          <Route path="post-jobs" element={<PostJobs />} />
          <Route path="subscribers" element={<Subscribers />} />
        </Route>
      </Route>
    </Route>
  )
);
