import React from 'react'
import {AboutHero, Billing, CallToA, Clients, FeedbackCard, GetStarted} from '../components';
import {Reviews} from '../sections';
import OurValues from '../components/OurValues';
import OurServices from '../components/OurServices';


const AboutUs = () => {
  return (

    <main>

    <section className='xl:padding-1 wide:padding-r padding-b'>
      <AboutHero />
    </section>
    <section className='padding-x py-10 bg-black'>
      <GetStarted /> 
    </section>
    <section className="bg-white padding">
        <OurValues />
    </section>
    <section className="bg-white padding">
        <OurServices />
    </section>
    <section className="bg-pale-blue padding">
        <Reviews />
      </section>
    <section className='padding-x py-10'>
      <CallToA /> 
    </section>

    </main>
  )
}

export default AboutUs