import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { Table, Pagination } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import CreateNewUser from "../forms/CreateNewUser";
import { setSearchTerm } from "../../store/slices/searchTerm";
import PostNewJob from "../forms/PostNewJob";

const PostJobs = () => {
  const searchTerm = useSelector((state) => state.searchTerm);
  const employers = useSelector((state) => state?.company?.companies);
  const dispatch = useDispatch();
  const [isPostJob, setIsPostJob] = useState(false);

  // Add a new state for the selected employer
  const [selectedEmployer, setSelectedEmployer] = useState(null);

  //control modal
  const [opened, { open, close }] = useDisclosure(false);

  // pagination property
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // admin companies
  const adminCompanies = employers.filter(
    (company) =>
      company.is_admin === true &&
      company.company_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // table rows
  const rows = adminCompanies
    ? adminCompanies
        .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
        .map((employer) => (
          <Table.Tr>
            <Table.Td className="p-4">{employer?.company_name}</Table.Td>
            <Table.Td>{employer?.email}</Table.Td>
            <Table.Td>
              {" "}
              {format(new Date(employer?.created_at), "dd/MM/yyyy")}
            </Table.Td>
            <Table.Td>{`${
              employer?.subscription_plan ? "premium" : "standard"
            }`}</Table.Td>
            <Table.Td>
              <button
                className="text-white p-2 px-4 font-semibold font-raleway bg-gold
              rounded-md cursor-pointer"
                onClick={() => {
                  setIsPostJob(true);
                  setSelectedEmployer(employer);
                  open();
                }}
              >
                Post Job
              </button>
            </Table.Td>
          </Table.Tr>
        ))
    : [];

  useEffect(() => {
    dispatch(setSearchTerm(""));
  }, []);
  return (
    <>
      <div className=" flex items-enter p-8 justify-between">
        <p className="font-bold text-2xl">Admin Compnanies</p>
        <button
          onClick={() => {
            setIsPostJob(false);
            open();
          }}
          className="text-white p-2 px-4 font-semibold font-raleway bg-black
              rounded-md cursor-pointer"
        >
          Create Company
        </button>
      </div>
      <section className="p-8">
        <div className="border bg-white rounded p-4">
          <div className="flex items-center justify-between mb-4">
            <div className="pl-2 border w-[420px] bg-gray-100 flex gap-3 items-center border-gray-500 rounded">
              <FaSearch />
              <input
                onChange={(e) => dispatch(setSearchTerm(e.target.value))}
                type="text"
                className="flex-1 h-full p-2 focus:outline-none rounded bg-transparent"
                placeholder="search company name..."
              />
            </div>
            <div className="">
              <div>
                Total: <span>{adminCompanies?.length}</span>
              </div>
            </div>
          </div>

          {/* Table */}
          <div>
            <Table
              // verticalSpacing={"md"}
              className="w-full  bg-white rounded-lg border-red-600"
              striped
              highlightOnHover
            >
              <Table.Thead className="rounded-lg text-gray-800 bg-gold">
                <Table.Tr className="text-left rounded-lg bg-gold">
                  <Table.Th className=" font-semibold p-4 rounded-tl-lg">
                    Company Name
                  </Table.Th>
                  <Table.Th className=" font-semibold">Email</Table.Th>
                  <Table.Th className="text-sm font-semibold">
                    Date Registered
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold">
                    Active Plan
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold rounded-tr-lg">
                    Action
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </div>
        </div>
        <div className="flex justify-center mt-6">
          <Pagination
            total={Math.ceil(adminCompanies?.length / rowsPerPage) || 1}
            value={currentPage}
            onChange={setCurrentPage}
            color="#333"
          />
        </div>
      </section>

      <Modal
        opened={opened}
        onClose={close}
        title={isPostJob ? "Post Job" : "Create New Employer"}
        size={"70%"}
      >
        {isPostJob ? (
          <PostNewJob employer={selectedEmployer} />
        ) : (
          <CreateNewUser />
        )}
      </Modal>
    </>
  );
};

export default PostJobs;
