import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { heroImage2, googleLogo } from "../assets/images";
import { BorderlessTextInput } from "../components/customFormikEle";
import { loginUser, closeModal, googleLogin } from "../store/slices/authSlice";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import ForgotPassword from "../components/ForgotPassword";
import { useGoogleLogin } from "@react-oauth/google";

const UserLogin = () => {
  const [opened, { open, close }] = useDisclosure(false); //modal controller

  const dispatch = useDispatch();
  const { isAuthenticated, token, loading } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();
  const decodedToken = token && jwtDecode(token);

  // this will set the openModal state to false
  const handleClose = () => {
    dispatch(closeModal());
    close();
  };

  // Formik Initial Values
  const initialValues = {
    email: "",
    password: "",
  };

  // Validation Schema
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^()#+-_={}])[A-Za-z\d@$!%*?&^()#+-_={}]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  });

  // Handle Form Submittion
  const onSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("username", values.email);
    formData.append("password", values.password);

    if (!navigator.onLine) {
      toast.error("No internet connection!");
    }
    try {
      // dispatch redux action
      dispatch(loginUser(formData));
    } catch (error) {
      // Handle the error here. For example, you can show a toast notification:
      toast.error("An error occurred while logging in. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  // Google Login
  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const access_token = codeResponse.access_token;
      dispatch(googleLogin(access_token));
    },
    onError: (error) => toast.error("Failed to login with google"),
  });

  // // Reset form when user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      if (decodedToken.user_type === "admin") {
        navigate("/admin");
        return;
      }

      navigate("/user");
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className="w-full max-sm:h-full h-screen max-sm:flex-col flex items-start max-sm:pt-20 pt-32 mb-20">
        <div className="w-1/2 max-sm:w-full h-full flex flex-col">
          <div className="absolute top-[20%] left-[10%] flex flex-col">
            <h1 className="font-raleway mt-[100px] max-sm:mt-10 text-4xl text-black max-sm:mr-[20px] my-4">
              Turn your ideas <br />
              <span className="font-bold">into reality</span>
            </h1>

            <p className="font-raleway text-black text-lg leading-8 mt-6 mb-5 max-sm:mb-2 max-sm:mr-[20px] sm:max-w-sm">
              Join our vast community of jobseekers and employers to deliver
              your skill and high expertise
            </p>
          </div>
          <img src={heroImage2} className="w-full h-full object-cover" alt="" />
        </div>

        <div className="w-1/2 pb-15 h-full bg-veryLightGray flex flex-col p-20 max-sm:px-10 max-sm:w-full justify-between items-center">
          <div className="w-full flex flex-col max-w-[500px]">
            <div className="w-full flex flex-col mb-2">
              <h3 className="text-3xl font-semibold font-raleway mb-2">
                Login
              </h3>
              <p className="text-basetext-sm font-raleway text-slate-gray mb-2">
                Welcome back! Please enter your details.
              </p>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="w-full flex flex-col">
                    <BorderlessTextInput
                      name="email"
                      placeholder="Email"
                      type="email"
                    />

                    <BorderlessTextInput
                      name="password"
                      placeholder="Password"
                      type="password"
                    />
                  </div>

                  <div className="w-full flex items-center justify-between">
                    <div className="w-full flex items-center">
                      <input type="checkbox" className="w-4 h-4 mr-2" />
                      <p className="text-sm font-raleway">
                        Remember me for 30 days
                      </p>
                    </div>

                    <p
                      onClick={open}
                      className="text-sm font-medium font-raleway whitespace-nowrap cursor-pointer underline underline-offset-2"
                    >
                      Forgot Password ?
                    </p>
                  </div>

                  <div className="w-full flex flex-col my-4">
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className={`w-full text-white my-2 font-semibold font-montserrat ${
                        isSubmitting ? "bg-gray-300" : "bg-[#060606]"
                      } rounded-md p-4 text-center flex items-center justify-center cursor-pointer`}
                    >
                      {isSubmitting ? "Submitting.." : "Log In"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="w-full flex flex-col">
              <Link to="/signup">
                <button className="w-full text-[#060606] my-2 font-semibold font-raleway bg-white border border-black/40 rounded-md p-4 text-center flex items-center justify-center cursor-pointer">
                  Register
                </button>
              </Link>
            </div>

            <div className="w-full flex items-center justify-center relative py-2">
              <div className="w-full h-[1px] bg-black"> </div>
              <p className="text-lg absolute text-black/80 bg-[#f5f5f5]">OR</p>
            </div>
            <div
              onClick={loginWithGoogle}
              className="w-full text-[#060606] my-2 font-semibold bg-white border border-black/40 rounded-md p-4 text-center flex items-center justify-center cursor-pointer"
            >
              <img src={googleLogo} className="h-6 mr-2" />
              Sign in with Google
            </div>
          </div>

          <div className="w-full flex items-center justify-center">
            <p className="text-sm font-raleway font-normal text-slate-gray">
              Dont't have an account?{" "}
              <Link to="/signup">
                <span className="font-semibold underline underline-offset-2 cursor-pointer">
                  Sign up for free!
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Modal opened={opened} title="Forgot Password" onClose={handleClose}>
        <ForgotPassword onUpdatePasword={handleClose} />
      </Modal>{" "}
    </>
  );
};

export default UserLogin;
