import React from "react";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";

const JobSeekerSettings = () => {
  return (
    <div>
      <nav className="bg-white">
        <ul className="flex  max-sm:flex-col justify-between">
          <li className="text-center w-1/3 p-2  max-sm:w-full">
            <NavLink
              className=" shadow border border-gray-300 w-full inline-block p-2"
              to="preferences"
            >
              Job Preferences
            </NavLink>
          </li>
          <li className="w-1/3 text-center p-2  max-sm:w-full">
            <NavLink
              className="shadow border border-gray-300 w-full inline-block p-2"
              to="account"
            >
              Account
            </NavLink>
          </li>
          <li className="w-1/3 text-center p-2 max-sm:w-full">
            <NavLink
              className="shadow border border-gray-300 w-full inline-block p-2"
              to="notifications"
            >
              Notifications
            </NavLink>
          </li>
        </ul>
      </nav>
      <section className="p-4">
        <Outlet />
      </section>
    </div>
  );
};

export default JobSeekerSettings;
