import React, { useEffect, useState } from "react";
import { fetchSingleJobListing } from "../store/slices/jobListingSlice";
import { createJobApplication } from "../store/slices/jobApplication";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import useCloudinaryWidget from "../hooks/useCloudinary";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import getUserType from "../utils/getUser";
import { options } from "../utils/ulParser";
import { currencyFormatter } from "../utils/numberFormatter";
import { FaExclamationTriangle, FaFile, FaTimes } from "react-icons/fa";
import { format } from "date-fns";
import parse from "html-react-parser";

const ApplicationForm = () => {
  const navigate = useNavigate();
  const [isProfileResumeInUse, setProfileResumeInUse] = useState(false); // check if user wants to use resume from profile
  const [isProfileCoverLetterInUse, setProfileCoverLetterInUse] =
    useState(false); // check if user wants to use resume from profile
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(null);

  const { id } = useParams(); //job listing id
  const { uploadToCloudinary } = useCloudinaryWidget();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.jobListing);
  const listing = state?.singleListing?.listing;
  const { job_seeker } = useSelector((state) => state?.jobSeeker);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const userType = getUserType();
  let formattedDate = "";
  if (listing?.due_date) {
    formattedDate = format(new Date(listing.due_date), "EEEE, MMMM do, yyyy");
  }
  const [coverLetterUrl, setCoverLetterUrl] = useState(""); // cover letter
  const [resumeUrl, setResumeUrl] = useState(""); // resume url
  const [resumeFileName, setResumeFileName] = useState(""); // resume file name
  const [coverLetterFileName, setCoverLetterFileName] = useState(""); // cover letter file name

  // Formik
  const initialValues = {
    cover_letter: "",
    resume: "",
    job_seeker_id: "",
    job_listing_id: "",
  };

  // handle file upload
  const handleFileUpload = async (type) => {
    setLoading(type);
    try {
      // Upload the file to cloudinary
      const { url, fileName } = await uploadToCloudinary();
      if (type === "resume") {
        setResumeUrl(url);
        setResumeFileName(fileName);
      } else {
        setCoverLetterUrl(url);
        setCoverLetterFileName(fileName);
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(null);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  //handle file delete
  const handleDelete = (type) => {
    if (type === "resume") {
      setResumeUrl("");
      setResumeFileName("");
    } else {
      setCoverLetterUrl("");
      setCoverLetterFileName("");
    }
  };

  function navigateToApplications() {
    navigate("/user/applications");
  }

  // load listing on page load
  useEffect(() => {
    dispatch(fetchSingleJobListing(id));
  }, [id]);

  // handle form submittion
  const onSubmit = (formikValues, { resetForm, setSubmitting }) => {
    if (!isChecked) {
      return toast.error("Please accept terms and conditions");
    }
    // check if user is authenticated
    if (!isAuthenticated) {
      toast.warning("Please login to apply to a job");
      setSubmitting(false);
      // navigate("/signin");
      return;
    }

    // check if user a job seeker
    if (userType !== "job_seeker") {
      toast.error("Please log in as a job seeker to apply for this job");
      setSubmitting(false);
      return;
    }

    const values = {
      ...formikValues,
      cover_letter: coverLetterUrl
        ? coverLetterUrl
        : job_seeker?.default_cover_letter,
      resume: resumeUrl ? resumeUrl : job_seeker?.default_resume,
      job_seeker_id: job_seeker.id,
      job_listing_id: id,
    };

    // Check if cover_letter and resume_url are provided
    if (!values.resume) {
      toast.error("Resume URL is required");
      setSubmitting(false);
      return;
    }

    const callBack = {
      resetForm,
      navigateToApplications,
    };

    dispatch(createJobApplication({ values, callBack }));
    setCoverLetterFileName("");
    setResumeFileName("");
    setCoverLetterUrl("");
    setResumeUrl("");
    setIsChecked(false);
    setProfileCoverLetterInUse(false);
    setProfileResumeInUse(false);
    setSubmitting(false);
  };
  return (
    <div class="container contain overflow-visible flex flex-col items-center px-6 mx-auto mt-10 max-sm:p-0 space-y-0 md:space-y-0 md:flex-row">
      <div className="w-full flex items-start pt-32 max-sm:pt-10 max-sm:flex-col">
        <div className="relative w-1/2 h-full flex flex-col max-sm:w-full">
          <div className="w-full flex flex-col max-w-[600px]">
            <div className="w-full flex flex-col mb-2 pr-10">
              <div className="mb-2">
                <span className="font-semibold text-xl font-raleway mb-2 mr-4 ">
                  Job Title
                </span>{" "}
                <br />
                <span className="font-raleway text-slate-gray">
                  {listing?.job_title}
                </span>
              </div>
              <div className="font-raleway mb-2">
                <p className="text-xl font-semibold">Job Description</p>
                <p className="font-raleway text-slate-gray mb-2 text-justify">
                  {typeof listing?.description === "string"
                    ? parse(listing.description, options)
                    : ""}
                </p>
              </div>

              <div className="mb-2">
                <span className="text-xl font-semibold font-raleway mb-2 mr-4 ">
                  Vacancy Location
                </span>
                <br />
                <span className="font-raleway text-slate-gray">
                  {`${listing?.state}, ${listing?.country}`}
                </span>
              </div>
              <div className="mb-2">
                <span className="font-semibold text-xl font-raleway mb-2 mr-4 ">
                  Compensation
                </span>
                <br />
                <div>
                  <span className="font-raleway text-slate-gray mr-5">
                    {`Min: ${currencyFormatter.format(
                      listing?.min_salary_range
                    )}`}
                  </span>
                  <span className="font-raleway text-slate-gray">
                    {`Max: ${currencyFormatter.format(
                      listing?.max_salary_range
                    )}`}
                  </span>
                </div>
              </div>

              <div className="mb-2">
                <span className="font-semibold text-xl font-raleway mb-2 mr-4 ">
                  Position Type
                </span>
                <br />
                <span className="font-raleway text-slate-gray">
                  {listing?.position || "Full Time"}
                </span>
              </div>
              <div className="mb-2">
                <span className="font-semibold text-xl font-raleway mb-2 mr-4 ">
                  Lowest Education Requirement
                </span>
                <br />
                <span className="font-raleway text-slate-gray">
                  {listing?.education_level || "Bachelors"}
                </span>
              </div>
              <div className="mb-2">
                <span className="font-semibold text-xl font-raleway mb-2 mr-4 ">
                  Application Deadline
                </span>
                <br />
                <span className="font-raleway text-slate-gray">
                  {formattedDate}
                </span>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>

        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form className="w-1/2 max-sm:w-full max-sm:p-4 h-full bg-veryLightGray flex flex-col p-12 justify-between items-center">
              <div className="w-full flex flex-col max-w-[500px]">
                {userType === "company" ? (
                  <div className="flex items-center bg-red-200 p-2 px-4 rounded">
                    <FaExclamationTriangle className="text-red-500 text-2xl mr-2" />
                    <span className="text-sm text-gray-700">
                      Only{" "}
                      <span className="font-bold text-red-600">
                        {" "}
                        JOB SEEKER{" "}
                      </span>{" "}
                      accounts can apply on a listing
                    </span>
                  </div>
                ) : (
                  <div>
                    <div className="w-full flex flex-col mb-2">
                      <h3 className="text-3xl font-semibold font-raleway mb-2">
                        Apply
                      </h3>
                      <p className="text-basetext-sm font-raleway text-slate-gray mb-2">
                        Please complete the form below.
                      </p>
                    </div>

                    <div className="w-full flex flex-col">
                      <div class=" items-center justify-center w-full py-4">
                        <div className=" mb-2">
                          <div className="flex items-center mb-2">
                            {!resumeUrl && (
                              <>
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 mr-2"
                                  checked={isProfileResumeInUse}
                                  onChange={(e) =>
                                    setProfileResumeInUse(e.target.checked)
                                  }
                                />
                                <p className="text-sm">
                                  use resume from profile
                                </p>
                              </>
                            )}
                          </div>
                          {!isProfileResumeInUse && (
                            <div className="w-full flex items-center justify-between">
                              <button
                                disabled={loading === "resume"}
                                type="button"
                                onClick={() => handleFileUpload("resume")}
                                className={`${
                                  loading === "resume"
                                    ? "bg-gray-300"
                                    : "bg-gold"
                                } hover:bg-gray-500 text-white font-bold py-2 px-4 rounded`}
                              >
                                {loading === "resume"
                                  ? "loading..."
                                  : "Upload Resume"}
                              </button>
                              {resumeFileName ? (
                                <div className="flex items-center border h-12 w-1/2 rounded-md px-2 bg-gray-200">
                                  <FaFile />
                                  <p className="flex-1 mx-2 text-sm">
                                    {resumeFileName}
                                  </p>
                                  <FaTimes
                                    className="text-red-600 cursor-pointer"
                                    onClick={() => handleDelete("resume")}
                                  />
                                </div>
                              ) : (
                                <div className="text-sm text-gray-500 px-5">
                                  No file uploaded
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="mt-12">
                          <div className="w-full flex items-center mb-2">
                            {!coverLetterUrl && (
                              <>
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 mr-2"
                                  checked={isProfileCoverLetterInUse}
                                  onChange={(e) =>
                                    setProfileCoverLetterInUse(e.target.checked)
                                  }
                                />
                                <p className="text-sm">
                                  {` use cover letter from profile (Optional)`}
                                </p>
                              </>
                            )}
                          </div>
                          {!isProfileCoverLetterInUse && (
                            <div className="w-full flex items-center justify-between">
                              <button
                                disabled={loading === "cover_letter"}
                                type="button"
                                onClick={() => handleFileUpload("cover_letter")}
                                className={`${
                                  loading === "cover_letter"
                                    ? "bg-gray-300"
                                    : "bg-gold"
                                } hover:bg-gray-500 text-white font-bold py-2 px-4 rounded`}
                              >
                                {loading === "cover_letter"
                                  ? "loading..."
                                  : "Upload Cover Letter"}
                              </button>
                              {coverLetterFileName ? (
                                <div className="flex items-center border h-12 w-1/2 rounded-md px-2 bg-gray-200">
                                  <FaFile />
                                  <p className="flex-1 mx-2 text-sm">
                                    {coverLetterFileName}
                                  </p>
                                  <FaTimes
                                    className="text-red-600 cursor-pointer"
                                    onClick={() => handleDelete("cover_letter")}
                                  />
                                </div>
                              ) : (
                                <div className="text-sm px-5 text-gray-500">
                                  No file uploaded
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex items-center justify-between mt-8">
                      <div className="w-full flex items-center">
                        <input
                          type="checkbox"
                          className="w-4 h-4 mr-2"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <p className="text-sm">I accept Terms and Conditions</p>
                      </div>
                    </div>

                    <div className="w-full flex flex-col ">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className={`w-full text-white my-2 font-semibold font-raleway ${
                          isSubmitting ? "bg-gray-300" : "bg-[#060606]"
                        } rounded-md p-4 text-center flex items-center justify-center cursor-pointer`}
                      >
                        {isSubmitting ? "Submitting..." : "Apply"}
                      </button>
                    </div>
                    <p className="text-sm text-gray-500">
                      NB: only use the "docs from profile" options after
                      uploading the document in the "Documents" section.
                    </p>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ApplicationForm;
