import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchSubscriptionsApi,
  fetchSubscriptionApi,
  initializeSubscriptionApi,
  verifySubscriptionApi,
  cancelSubscriptionApi,
  updateCardApi,
  fetchSubscriptionPlansApi,
  updateSubscriptionPlanApi,
} from "../../api/subscriptionApi";
import { toast } from "react-toastify";

const initialState = {
  subscriptions: [],
  loading: false,
  error: null,
  subscription: {},
  plans: [],
  authorizationUrl: null,
};

export const fetchSubscriptions = createAsyncThunk(
  "subscription/fetchSubscriptions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchSubscriptionsApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// this slice will either fetch a single subscriber or the subscription plans
export const fetchSubscriber = createAsyncThunk(
  "subscription/fetchSubscriber",
  async ({ ...params }, { rejectWithValue }) => {
    try {
      const response = await fetchSubscriptionApi({ ...params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// initialize subscription
export const initializeSubscription = createAsyncThunk(
  "subscription/initializeSubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await initializeSubscriptionApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// verify subscription
export const verifySubscription = createAsyncThunk(
  "subscription/verifySubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await verifySubscriptionApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// cancel subscription
export const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await cancelSubscriptionApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// upadte card
export const updateCard = createAsyncThunk(
  "subscription/updateCard",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateCardApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// get all subscription plans
export const fetchSubscriptionPlans = createAsyncThunk(
  "subscription/fetchSubscriptionPlans",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchSubscriptionPlansApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// update plans
export const updateSubscriptionPlan = createAsyncThunk(
  "subscription/updateSubscriptionPlan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateSubscriptionPlanApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptions = action.payload;
        state.error = null;
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.subscriptions = [];
      })
      .addCase(fetchSubscriber.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubscriber.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload.data)) {
          state.plans = action.payload.data;
        } else {
          state.subscription = action.payload;
        }
        state.error = null;
      })
      .addCase(fetchSubscriber.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.subscription = {};
      })
      .addCase(initializeSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(initializeSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.authorizationUrl = action.payload;
        state.error = null;
      })
      .addCase(initializeSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.authorizationUrl = null;
      })
      .addCase(verifySubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifySubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.authorizationUrl = null;
        state.subscription = action.payload;
      })
      .addCase(verifySubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.subscription = action.payload;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCard.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.authorizationUrl = action.payload;
      })
      .addCase(updateCard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(fetchSubscriptionPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubscriptionPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.plans = action.payload;
        state.error = null;
      })
      .addCase(fetchSubscriptionPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.plans = [];
      })
      .addCase(updateSubscriptionPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSubscriptionPlan.fulfilled, (state, action) => {
      state.loading = false;
      state.authorizationUrl = action.payload;
      state.error = null;
      })
      .addCase(updateSubscriptionPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.authorizationUrl = null;
      });
  },
});

export default subscriptionSlice.reducer;
