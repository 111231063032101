import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { gscMain } from "../assets/images";
import Button from "../components/Button";
import { userAvatar } from "../assets/images";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { RiMenu3Fill } from "react-icons/ri";
import {
  FaTimes,
  FaUser,
  FaPowerOff,
  FaBuilding,
} from "react-icons/fa";
import getUserType from "../utils/getUser";
import { Popover } from "@mantine/core";
import { logout } from "../store/slices/authSlice";

const Nav = () => {
  // Control for the popover
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userType = getUserType();
  const companyLogo = useSelector(
    (state) => state?.company?.company?.company?.logo
  );
  const jobSeekerProfileImg = useSelector(
    (state) => state?.jobSeeker?.job_seeker?.profile_picture
  );

  //  Handle logging out
  const handleSignOut = () => {
    setOpened((o) => !o);
    // Dispatch LOGOUT action
    dispatch(logout());
    // And then redirect them to the login page:
    navigate("/");
  };

  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };
  // State to manage the navbar's visibility
  const [mobilenav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!mobilenav);
  };

  const [isScrolled, setIsScrolled] = useState(false);
  const state = useSelector((state) => state.auth);
  const decodedToken = state?.token && jwtDecode(state.token);

  const checkScroll = () => {
    if (window.scrollY > 60) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  useEffect(() => {
    let handler = () => {
      setNav(false);
    };
    document.addEventListener("mousedown", handler);
  });

  useEffect(() => {}, [state.isAuthenticated, state.token]);

  return (
    <header
      className={`padding-x py-4 z-50 w-full fixed transition-colors duration-500 ${
        isScrolled ? "bg-white shadow-md" : ""
      }`}
    >
      <nav className={`flex justify-between items-center w-full }`}>
        <Link to="/">
          <img src={gscMain} alt="logo" width={50} height={50} />
        </Link>
        <ul className="flex-1 flex justify-center items-center gap-10 max-lg:hidden">
          <Link to="/">
            <li className="font-raleway leading-normal text-lg text-slate-gray">
              Home
            </li>
          </Link>
          <Link to="/about">
            <li className="font-raleway leading-normal text-lg text-slate-gray">
              About
            </li>
          </Link>
          {/*<Link to="/membership">
                <li className='font-raleway leading-normal text-lg text-slate-gray'>Membership</li>
            </Link>*/}
          <Link to="/listings">
            <li className="font-raleway leading-normal text-lg text-slate-gray">
              Apply
            </li>
          </Link>
          <Link
            to="https://blog.gscmatchpoint.com/"
            rel="noreferrer"
            target={"_blank"}
          >
            <li className="font-raleway leading-normal text-lg text-slate-gray">
              Blog
            </li>
          </Link>
          <Link to="/contact">
            <li className="font-raleway leading-normal text-lg text-slate-gray">
              Contact Us
            </li>
          </Link>
        </ul>

        <ul className="flex-1 flex justify-center items-center gap-10 max-lg:hidden">
          {state.isAuthenticated && (
            <Popover opened={opened} onChange={setOpened}>
              <Popover.Target>
                <div
                  onClick={() => setOpened((o) => !o)}
                  className="rounded-full border overflow-hidden cursor-pointer"
                >
                  {state.isAuthenticated &&
                  userType === "company" &&
                  companyLogo ? (
                    <img
                      src={companyLogo}
                      alt="logo"
                      className="rounded-full object-cover border-2 border-goldinline-block h-12 w-12 rounded-full ring-2 ring-white"
                    />
                  ) : state.isAuthenticated &&
                    userType === "job_seeker" &&
                    jobSeekerProfileImg ? (
                    <img
                      src={jobSeekerProfileImg}
                      alt="profilePicture"
                      className="rounded-full object-cover border-2 border-goldinline-block h-12 w-12 rounded-full ring-2 ring-white"
                    />
                  ) : (
                    <img
                      className="rounded-full"
                      src={userAvatar}
                      alt="userAvatar"
                      width={35}
                      height={35}
                    />
                  )}
                </div>
              </Popover.Target>

              <Popover.Dropdown>
                <ul className="flex flex-col gap-2">
                  <Link
                    to={`${userType === "admin" ? "/admin" : "/user"}`}
                    onClick={() => setOpened((o) => !o)}
                  >
                    <li className="font-raleway leading-normal text-dark-gray flex items-center gap-2">
                      <FaUser />
                      <span>Profile</span>
                    </li>
                  </Link>
                  <Link to="/" onClick={handleSignOut}>
                    <li className="font-raleway leading-normal text-dark-gray flex items-center gap-2">
                      <FaPowerOff />
                      <span>Sign Out</span>
                    </li>
                  </Link>
                </ul>
              </Popover.Dropdown>
            </Popover>
          )}

          {!state.isAuthenticated && (
            <Popover opened={opened} onChange={setOpened}>
              <Popover.Target>
                <div
                  className="bg-gold p-3 rounded rounded-3xl cursor-pointer text-white font-bold font-raleway w-[100px] flex items-center justify-center"
                  onClick={() => setOpened((o) => !o)}
                >
                  Sign In
                </div>
              </Popover.Target>
              <Popover.Dropdown>
                <ul className="flex flex-col gap-2">
                  <Link to="/signin" onClick={() => setOpened((o) => !o)}>
                    <li className="font-raleway leading-normal text-dark-gray flex items-center gap-2">
                      <FaUser />
                      <span>Sign in as Jobseeker</span>
                    </li>
                  </Link>
                  <Link to="/signin" onClick={handleSignOut}>
                    <li className="font-raleway leading-normal text-dark-gray flex items-center gap-2">
                      <FaBuilding />
                      <span>Sign in as Employer</span>
                    </li>
                  </Link>
                </ul>
              </Popover.Dropdown>
            </Popover>
          )}

          {state.isAuthenticated && decodedToken.user_type === "company" && (
            <Link to="/postjob">
              <li className="font-raleway leading-normal text-lg text-dark-gray">
                <div className="px-0 flex max-sm:justify-center items-center max-sm:full max:sm:absolute max-sm:mb-8 max-sm:w-full p-2">
                  <Button className="hover:bg-gray-600" label="Post a Job" />
                </div>
              </li>
            </Link>
          )}
        </ul>

        <div className="hidden max-lg:block transition" onClick={handleNav}>
          {/*<img src={hamburger} alt="Hamburger" width={25} height={25} />*/}
          {mobilenav ? <FaTimes /> : <RiMenu3Fill />}
        </div>

        {/* Beginning of mobile nav */}

        <nav
          className={
            mobilenav
              ? "fixed md:hidden p-10 left-0 top-0 w-[80%] h-full border-r border-r-gray-900 bg-[#000300] opacity-95 ease-in-out duration-500"
              : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]"
          }
        >
          <ul className="">
            <Link to="/">
              <li className="font-raleway leading-normal text-lg text-white py-2">
                Home
              </li>
            </Link>
            <Link to="/about">
              <li className="font-raleway leading-normal text-lg text-white py-2">
                About
              </li>
            </Link>

            <Link to="/listings">
              <li className="font-raleway leading-normal text-lg text-white py-2">
                Apply
              </li>
            </Link>
            <Link to="/contact">
              <li className="font-raleway leading-normal text-lg text-white py-2">
                Contact Us
              </li>
            </Link>
          </ul>

          <ul className="">
            {state.isAuthenticated && (
              <Link
                to={`${userType === "admin" ? "/admin" : "/user"}`}
                className="rounded-full"
              >
                {state.isAuthenticated &&
                userType === "company" &&
                companyLogo ? (
                  <img
                    src={companyLogo}
                    alt="logo"
                    width={50}
                    height={50}
                    className="rounded-full object-cover border-2 border-goldinline-block h-12 w-12 rounded-full ring-2 ring-white"
                  />
                ) : state.isAuthenticated &&
                  userType === "job_seeker" &&
                  jobSeekerProfileImg ? (
                  <img
                    src={jobSeekerProfileImg}
                    alt="profile Image"
                    className="rounded-full object-cover border-2 border-goldinline-block h-12 w-12 rounded-full ring-2 ring-white"
                  />
                ) : (
                  <img
                    className="rounded-full"
                    src={userAvatar}
                    alt="userAvatar"
                    width={35}
                    height={35}
                  />
                )}
              </Link>
            )}

            {!state.isAuthenticated && (
              <Link to="/signin">
                <button className="bg-gold px-10 mt-5 py-2 rounded-full font-raleway leading-normal text-lg text-white">
                  Sign In
                </button>
              </Link>
            )}

            {state.isAuthenticated && decodedToken.user_type === "company" && (
              <Link to="/postjob">
                <li className="font-raleway leading-normal text-lg text-dark-gray">
                  <div className="px-0 flex flex-start max-sm:mt-5 items-center max-sm:full max:sm:absolute max-sm:mb-8 max-sm:w-full p-2">
                    <Button className="hover:bg-gray-600" label="Post a Job" />
                  </div>
                </li>
              </Link>
            )}
          </ul>
        </nav>
        {/* End of mobile nav */}
      </nav>
    </header>
  );
};

export default Nav;
